<template>
    <section class="services">
        <h2>我们的服务</h2>
        <div class="service-container">
            <service-card name="自由购物">
                <img src="@/assets/images/shipping.png" alt="自由购物"
            /></service-card>

            <service-card name="轻松订购">
                <img src="@/assets/images/order.png" alt="轻松订购" />
            </service-card>
            <service-card name="智能储蓄">
                <img src="@/assets/images/savings.png" alt="智能储蓄" />
            </service-card>
            <service-card name="促销">
                <img src="@/assets/images/promos.png" alt="促销" />
            </service-card>
            <service-card name="24/7支持">
                <img src="@/assets/images/support.png" alt="24/7支持"
            /></service-card>
            <service-card name="送货上门服务">
                <img src="@/assets/images/delivery.png" alt="送货上门服务" />
            </service-card>
        </div>
    </section>
</template>

<script>
import ServiceCard from "@/components/home_components/cards/ServiceCard.vue";
export default {
    name: "ServiceSection",
    components: {
        "service-card": ServiceCard,
    },
};
</script>

<style scoped>
.services {
    text-align: center;
}

.services h2 {
    margin-bottom: 2rem;
}

.service-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
}

img {
    height: 100px;
    width: 100px;
}

@media (max-width: 399px) {
    .service-container {
        padding: 2rem;
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: initial;
    }
}
</style>
