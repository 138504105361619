<template>
    <main-header />



  <div class="banner">
    <el-carousel :interval="3000" arrow="never">
      <el-carousel-item height="100%">
        <img src="@/assets/images/footer/WechatIMG1169.jpg" alt="" style="width: 100%;height: 100%;" />
      </el-carousel-item>
<!--      <el-carousel-item height="100%">-->
<!--        <img src="@/assets/images/footer/background-2.jpg" alt="" style="width: 100%;height: 100%;" />-->
<!--      </el-carousel-item>-->
<!--      <el-carousel-item height="100%">-->
<!--        <img src="@/assets/images/footer/background-3.jpg" alt="" style="width: 100%;height: 100%;" />-->
<!--      </el-carousel-item>-->
<!--      <el-carousel-item height="100%">-->
<!--        <img src="@/assets/images/footer/background-4.jpg" alt="" style="width: 100%;height: 100%;" />-->
<!--      </el-carousel-item>-->
    </el-carousel>
  </div>

<!--    <section class="hero__section">-->
<!--        <div class="container">-->
<!--            <div class="hero__text">-->
<!--                <h4>1以旧换新报价</h4>-->
<!--                <h1>超值交易</h1>-->
<!--                <h2>在所有产品上</h2>-->
<!--                <p>使用优惠券节省更多费用，最高可享受七折优惠！</p>-->
<!--                <router-link to="/shop">-->
<!--                    <action-button btnvalue="开始购物吧" />-->
<!--                </router-link>-->
<!--            </div>-->
<!--        </div>-->
<!--    </section>-->



<!--    <service-section />-->
<!--    <section class="feature__section">-->
<!--        <div class="container">-->
<!--            <featured-products :featuredProducts="featuredProducts" />-->
<!--        </div>-->
<!--    </section>-->

<!--    <banner-section />-->
<!--    <new-arrivals :newArrivals="newArrivals" />-->
    <advert-section />
<!--    <news-letter />-->
    <main-footer />
</template>

<script>
import ServiceSection from "@/components/home_components/ServiceSection.vue";
import BannerSection from "@/components/home_components/BannerSection.vue";
import NewArrivals from "@/components/home_components/products/NewArrivals.vue";
import AdvertSection from "@/components/home_components/AdvertSection.vue";
import FeaturedProducts from "@/components/home_components/products/FeaturedProducts.vue";
import ActionButton from "@/components/ActionButton.vue";
import MainHeader from "@/components/MainHeader.vue";
// import NewsLetter from "@/components/NewsLetter.vue";
import MainFooter from "@/components/MainFooter.vue";

import axios from "axios";
import { mapActions } from "vuex";

export default {
    name: "HomeView",
    data() {
        return {
            products: [],
        };
    },
    methods: {
        ...mapActions(["set_products"]),
    },
    components: {
        ServiceSection,
        BannerSection,
        NewArrivals,
        AdvertSection,
        FeaturedProducts,
        ActionButton,
        MainHeader,
        // NewsLetter,
        MainFooter,
    },
    computed: {
        featuredProducts() {
            return this.products.slice(1, 5);
        },
        newArrivals() {
            return this.products.slice(5, 9);
        },
    },
    // async created() {
    //     let res1 = await axios.get("https://thegorana.herokuapp.com/products");
    //     this.products = res1.data.results.map((product) => {
    //         product.images[0] = product.images[0].replace("http", "https");
    //         return product;
    //     });
    //     this.set_products(this.products);
    // },
};
</script>

<style scoped>
/* Hero Section */
/*.hero__section {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    height: calc(100vh - 65px);*/
/*    background-image: url("@/assets/images/hero4.png");*/
/*    background-position: 60% 30%;*/
/*    background-size: cover;*/
/*}*/
.banner {
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  height: 680px;
  background-position: 60% 30%;
  background-size: cover;
  margin-bottom: -80px;
}

.hero__text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.hero__text h4 {
    padding-bottom: 1rem;
}

.hero__text h1 {
    color: var(--dim-blue);
}

.hero__text p {
    margin-bottom: 1rem;
}





@media (max-width: 870px) {
  /deep/.el-carousel__item {
    height: 220px !important;
  }

  /deep/.el-carousel__item>img {
    height: 220px !important;
  }

  /deep/.el-carousel__container {
    height: 220px !important;
  }

  /deep/.el-carousel__container>img {
    height: 220px !important;
  }

  /*/deep/.class-list {*/
  /*  margin-top: 50px !important;*/
  /*}*/

  /*.banner>img {*/
  /*  height: 220px;*/
  /*  background: rgba(3, 12, 37, 0.16);*/
  /*  position: relative;*/
  /*}*/

  /*.el-carousel>img {*/
  /*  height: 220px !important;*/
  /*  transform: none !important;*/
  /*}*/

  /*/deep/.el-carousel__container {*/
  /*  height: 220px;*/
  /*}*/

  .banner {
    height: 220px;
    background-position: 60% 30%;
    background-size: cover;
    margin-bottom: -40px;
  }

}


/*组件轮播样式*/
 .el-carousel__item h3 {
   color: #475669;
   font-size: 18px;
   opacity: 0.75;
   height: 100%;
   line-height: 50%;
   margin: 0;
 }

/deep/.el-carousel__arrow {
  margin: 0px 10% !important;
  width: 56px;
  height: 56px;
}

/deep/[class^='el-icon-'] {
  font-size: 25px;
}

/deep/.el-carousel__container>button {
  background-color: #ffffff !important;
  opacity: 0.2;
}

/deep/.el-carousel__container>button:hover {
  opacity: 0.7;
}

/deep/.el-icon-arrow-right {
  color: #9b9da2 !important;
}

/deep/.el-icon-arrow-left {
  color: #9b9da2 !important;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/*.el-carousel__item {*/
/*  transform: none !important;*/
/*}*/

.banner>img {
  height: 680px;
  background: rgba(3, 12, 37, 0.16);
  position: relative;
}

.el-carousel>img {
  height: 680px !important;
  transform: none !important;
}

/deep/.el-carousel__container {
  height: 630px;
}


</style>
