<template>
  <section>
    <div class="container">
<!--      <div class="ad1">-->
<!--        <advert-card-->
<!--          advertText="这些最适合夏天"-->
<!--          advertTitle="春季/夏季"-->
<!--          buttonText="更多"-->
<!--          backgroundImage="first"-->
<!--        />-->
<!--        <advert-card-->
<!--          advertText="全新温暖舒适的冬季系列"-->
<!--          advertTitle="冬天"-->
<!--          buttonText="收藏"-->
<!--          backgroundImage="second"-->
<!--        />-->
<!--      </div>-->
      <div class="ad2">
        <advert-card
          advertTitle="胡佳"
          backgroundImage="third"
          buttonText="查看视频"
          url-video="http://sdvoice.com.cn/shipin/hj.mp4"
        />
        <advert-card
          advertTitle="王雪"
          backgroundImage="fourth"
          buttonText="查看视频"
          url-video="http://sdvoice.com.cn/shipin/wx.mp4"
        />
        <advert-card
          advertTitle="左东兴"
          backgroundImage="fifth"
          buttonText="查看视频"
          url-video="http://sdvoice.com.cn/shipin/zdx.mp4"
        />
      </div>
    </div>
  </section>
</template>

<script>
import AdvertCard from "./cards/AdvertCard.vue";

export default {
  components: { AdvertCard },
  name: "AdvertSection",
};
</script>

<style scoped>
.ad1,
.ad2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ad2 {
  margin-top: 3rem;
  gap: 2rem;
}

@media (max-width: 670px) {
  .ad1,
  .ad2 {
    flex-wrap: wrap;
    gap: 2rem;
  }
}
</style>