<template>
    <footer>
        <div class="container">
<!--            <router-link to="/">-->
<!--                <h3 class="logo">声达网络</h3>-->
<!--            </router-link>-->
            <div class="footer">
                    <div class="footer-col">
<!--                        <h4>关于我们</h4>-->
                      <span>
                        <a href="https://jbts.mct.gov.cn" target="_blank">全国文化和旅游市场网上举报投诉处理系统 </a>|
                        <a href="https://www.12377.cn" target="_blank">网上有害信息举报专区 </a>|
                        <a href="#/t8">未成年人使用条款</a> |
<!--                        <a href="http://sdvoice.com.cn/shipin/wwz.jpg" target="_blank">网文证查询 </a>-->
                        <a href="https://zwfw.mct.gov.cn/whscjydw?type=gb" target="_blank">网文证查询 </a>
                      </span>
                        <span>
                            <strong>地址: </strong>辽宁省沈阳市铁西区保工北街3号（17-2）
                        </span>
                        <span>
                            沈阳声达网络科技有限公司
                        </span>
                        <span>
                            <strong>联系电话: </strong>18802444733
                        </span>
                      <span>
                            <strong>备案号: </strong>辽ICP备20230122339号-2
                        </span>
                      <span>

<!--                    <img src="@/assets/images/footer/logo.jpg" width="100px" height="100px" alt="paypal" />-->
                        <img src="@/assets/images/footer/gzhewm.jpg"  width="100px" height="100px" alt="paypal" />
                        <a href="http://name:80/ccnt/sczr/service/business/emark/toDetail/" target="_blank">
                          <img src="@/assets/images/footer/111.jpg" width="100px" height="100px" alt="paypal" />
                        </a>
<!--                        <img src="@/assets/images/footer/wwz.jpg"  width="100px" height="100px" alt="paypal" />-->
<!--                        <img src="@/assets/images/footer/xcxewm.jpg"  width="100px" height="100px" alt="paypal" />-->
                      </span>
                    </div>
                    <div class="footer-col">
<!--                        <h4>安装应用程序</h4>-->
<!--                        <p>苹果市场或者安卓市场</p>-->
<!--                        <div class="apps">-->
<!--                            <a href="/" class="download-btn">-->
<!--                                <svg-->
<!--                                    xmlns="http://www.w3.org/2000/svg"-->
<!--                                    width="16"-->
<!--                                    height="16"-->
<!--                                    fill="currentColor"-->
<!--                                    class="bi bi-apple"-->
<!--                                    viewBox="0 0 16 16"-->
<!--                                >-->
<!--                                    <path-->
<!--                                        d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z"-->
<!--                                    />-->
<!--                                    <path-->
<!--                                        d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z"-->
<!--                                    />-->
<!--                                </svg>-->
<!--                                <div class="btn-text">-->
<!--                                    <p>下载<span>Apple Store</span></p>-->
<!--                                </div>-->
<!--                            </a>-->
<!--                            <a href="/" class="download-btn">-->
<!--                                <svg-->
<!--                                    xmlns="http://www.w3.org/2000/svg"-->
<!--                                    width="16"-->
<!--                                    height="16"-->
<!--                                    fill="currentColor"-->
<!--                                    class="bi bi-google"-->
<!--                                    viewBox="0 0 16 16"-->
<!--                                >-->
<!--                                    <path-->
<!--                                        d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"-->
<!--                                    />-->
<!--                                </svg>-->
<!--                                <div class="btn-text">-->
<!--                                    <p>Download on <span>Google Play</span></p>-->
<!--                                </div>-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <p>安全支付网关</p>-->
<!--                        <div class="payment-methods">-->
<!--                           <span>-->
<!--                             <img src="@/assets/images/paypal.png" alt="paypal" />-->
<!--                           </span>-->
<!--                            <img-->
<!--                                src="@/assets/images/mastercard.png"-->
<!--                                alt="mastercard"-->
<!--                            />-->
<!--                            <img src="@/assets/images/visa.png" alt="visa" />-->
<!--                            <img src="@/assets/images/paysafe.png" alt="paysafe" />-->
<!--                        </div>-->
                    </div>

<!--                <div class="footer-col">-->
<!--                    <h4>联系我们</h4>-->
<!--                    <span>-->
<!--                        <strong>地址: </strong>北京市开阿卡服饰有限责任公司-->
<!--                    </span>-->
<!--                    <span-->
<!--                        ><strong>电话: </strong>010-898989898</span-->
<!--                    >-->
<!--                    <span><strong>工作时间: </strong>8:00 - 20:00</span>-->
<!--                </div>-->
<!--                <div class="footer-col">-->
<!--                    <h4>关于我们</h4>-->
<!--                    <a href="#">关于我们</a>-->
<!--                    <a href="#">交货信息</a>-->
<!--                    <a href="#">隐私政策</a>-->
<!--                    <a href="#">条款和条件</a>-->
<!--                    <a href="#">联系我们</a>-->
<!--                </div>-->
<!--                <div class="footer-col">-->
<!--                    <h4>我的账号</h4>-->
<!--                    <a href="#">登录</a>-->
<!--                    <a href="#">购物车</a>-->
<!--                    <a href="#">我的心愿单</a>-->
<!--                    <a href="#">跟踪我的订单</a>-->
<!--                    <a href="#">帮助</a>-->
<!--                </div>-->

<!--                <div class="footer-col install">-->
<!--                    <h4>安装应用程序</h4>-->
<!--                    <p>苹果市场或者安卓市场</p>-->
<!--                    <div class="apps">-->
<!--                        <a href="/" class="download-btn">-->
<!--                            <svg-->
<!--                                xmlns="http://www.w3.org/2000/svg"-->
<!--                                width="16"-->
<!--                                height="16"-->
<!--                                fill="currentColor"-->
<!--                                class="bi bi-apple"-->
<!--                                viewBox="0 0 16 16"-->
<!--                            >-->
<!--                                <path-->
<!--                                    d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z"-->
<!--                                />-->
<!--                                <path-->
<!--                                    d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282z"-->
<!--                                />-->
<!--                            </svg>-->
<!--                            <div class="btn-text">-->
<!--                                <p>下载<span>Apple Store</span></p>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                        <a href="/" class="download-btn">-->
<!--                            <svg-->
<!--                                xmlns="http://www.w3.org/2000/svg"-->
<!--                                width="16"-->
<!--                                height="16"-->
<!--                                fill="currentColor"-->
<!--                                class="bi bi-google"-->
<!--                                viewBox="0 0 16 16"-->
<!--                            >-->
<!--                                <path-->
<!--                                    d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"-->
<!--                                />-->
<!--                            </svg>-->
<!--                            <div class="btn-text">-->
<!--                                <p>Download on <span>Google Play</span></p>-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <p>安全支付网关</p>-->
<!--                    <div class="payment-methods">-->
<!--                        <img src="@/assets/images/paypal.png" alt="paypal" />-->
<!--                        <img-->
<!--                            src="@/assets/images/mastercard.png"-->
<!--                            alt="mastercard"-->
<!--                        />-->
<!--                        <img src="@/assets/images/visa.png" alt="visa" />-->
<!--                        <img src="@/assets/images/paysafe.png" alt="paysafe" />-->
<!--                    </div>-->
<!--                </div>-->
<!--                <p class="copyright">-->
<!--                   声达网络. &copy; 2023. xxx有限公司-->
<!--                    <span>Vue.❤️</span>-->
<!--                </p>-->
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "MainFooter",
};
</script>

<style scoped>
footer {
    border-top: 1px solid var(--brown);
}
.footer {
    display: flex;
    /*align-items: flex-start;*/
    /*justify-content: space-between;*/
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-block: 4rem;
    gap: 1.5rem;
}

.logo {
    padding-top: 2rem;
}

.footer-col {
    display: flex;
    /*align-items: flex-start;*/
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
}

.footer-col h4 {
    padding-bottom: 1.3rem;
}

.footer-col a {
    font-size: 1.6rem;
}

.footer-col a:hover {
    color: var(--dark-green);
}

.footer-col span {
    display: block;
    font-size: 1.6rem;
}
strong {
    color: var(--text);
}

.social-links a {
    padding-right: 1.4rem;
}

.copyright {
    font-size: 1.7rem;
    width: 100%;
    text-align: center;
}

.copyright span {
    font-weight: 700;
    color: var(--dark-green);
}

.install p {
    padding-block: 0;
}

.apps {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.download-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    border: 2px solid var(--text);
    border-radius: 6px;
    padding: 8px;
    text-align: center;
}

.download-btn svg {
    font-size: 2rem;
}

.apps a:hover {
    background-color: var(--dark-green);
    color: white;
    border-color: var(--dark-green);
}

.apps a:hover p {
    color: white;
}

.payment-methods {
    display: flex;
    align-items: center;
    gap: 10px;
}
.payment-methods img {
    height: 20px;
    width: 100%;
}

.payment-methods svg:hover {
    cursor: pointer;
    color: var(--dark-green);
}

@media (max-width: 399px) {
    .download-btn p {
        font-size: 1.4rem;
    }
    .download-btn span {
        font-size: 1.4rem;
    }
}

@media (max-width: 345px) {
    .apps {
        display: flex;
        flex-direction: column;
    }
}
</style>
